import { BrowserRouter as Router } from 'react-router-dom'
import { AppRoutes } from 'routes/Routes'
import { useContext } from 'react'
import { AppContext } from 'context'
import ChatBubble from 'components/core/Utils/BiBot'
import { MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import botLogo from '../src/assets/logo/favicon-black.png'

function App () {
  const { state } = useContext(AppContext)
  return (
    <MantineProvider>
    <div className={`App ${state.theme}`}>
      <Router>
          <AppRoutes />
      </Router>
      <ChatBubble title="PreAuditAi" Chatcolor='#376188' userImage={botLogo}/>
    </div>
    </MantineProvider>
  )
}

export default App
